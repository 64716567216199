import WOW from 'wowjs';
$(function ($) {
    'use strict';

    /*
    |--------------------------------------------------------------------------
    | TABLE OF CONTENTS:
    |--------------------------------------------------------------------------
    |
    | 1. Preloader
    | 2. Mobile Menu
    | 3. Sticky Header
    | 4. Dynamic Background
    | 5. Isotop Initialize
    | 6. Modal Video
    | 7. Tabs
    | 8. Counter Animation
    | 9. Progress Bar
    | 10. Cursor Animation
    |
    */
    
    
    $.exists = function (selector) {
      return $(selector).length > 0;
    };

    $(document).ready(function() {
      $('body').trigger('scroll');
      $('body').trigger('resize');
      preloader();
      isotopInit();
    });
   

    $(function () {
        $('body').trigger('resize');
        mainNav();
        stickyHeader();
        dynamicBackground();
        isotopInit();
        //modalVideo();
        //tabs();
        counterInit();
        progressBar();
        if ($.exists('.wow')) {
          
          const wow = new WOW.WOW({
            live: false
        });
          wow.init();
          wow.sync();
        }
      });
    
      $('body').on('scroll', function () {
        counterInit();
      });
    
    /*--------------------------------------------------------------
        1. Preloader
    --------------------------------------------------------------*/
    function preloader() {
        $('.cs_preloader_in').fadeOut();

        if (!$.exists('.cs_hero')){
          $('header').addClass('cs_header_bg_secondary');
        }
        
        $('.cs_preloader').delay(15).fadeOut('slow');
    }

    /*--------------------------------------------------------------
        2. Mobile Menu
    --------------------------------------------------------------*/
    function mainNav() {
        // Create the inner <span> element
        var $innerSpan = $('<span></span>');

        // Create the outer <span> element with the class 'cs_menu_toggle' and append the inner <span>
        var $outerSpan = $('<span>', { class: 'cs_menu_toggle' }).append($innerSpan);

        // Append the outer <span> element to the target element with the class 'cs_nav'
        $('.cs_nav').append($outerSpan);

        // Create the new element
        var $newSpan = $('<span>', { class: 'cs_menu_dropdown_toggle' });
        // Append the new element to the first matching target element
        $('.menu-item-has-children').first().append($newSpan);

        $('.cs_menu_toggle').on('click', function () {
        $(this)
            .toggleClass('cs_toggle_active')
            .siblings('.cs_nav_list')
            .slideToggle();
        });
        $('.cs_menu_dropdown_toggle').on('click', function () {
        $(this).toggleClass('active').siblings('ul').slideToggle();
        $(this).parent().toggleClass('active');
        });
    }


    /*--------------------------------------------------------------
        3. Sticky Header
    --------------------------------------------------------------*/
    
    function stickyHeader() {
        var lastScrollTop = 0;
        var header = $('header');
        var headerHeight = header.outerHeight() + 30;

        $('body').scroll(function () {
            var windowTop = $(this).scrollTop();

            if (windowTop >= headerHeight) {
                header.addClass('cs_gescout_sticky');
            } else {
                header.removeClass('cs_gescout_sticky');
                header.removeClass('cs_gescout_show');
            }

            if (header.hasClass('cs_gescout_sticky')) {
                if (windowTop < lastScrollTop) {
                    header.addClass('cs_gescout_show');
                } else {
                    header.removeClass('cs_gescout_show');
                }
            }
            lastScrollTop = windowTop;
        });
    }

    /*--------------------------------------------------------------
        4. Dynamic Background
    --------------------------------------------------------------*/
    function dynamicBackground() {
        $('[data-src]').each(function () {
            var src = $(this).attr('data-src');
            $(this).css({
                'background-image': 'url(' + src + ')',
            });
        });
    }

    /*--------------------------------------------------------------
        5. Isotop Initialize
    --------------------------------------------------------------*/
    function isotopInit() {
        if ($.exists('.cs_isotop')) {
        $('.cs_isotop').isotope({
            itemSelector: '.cs_isotop_item',
            transitionDuration: '0.60s',
            percentPosition: true,
            masonry: {
            columnWidth: '.cs_grid_sizer',
            },
        });
        /* Active Class of Portfolio*/
        $('.cs_isotop_filter ul li').on('click', function (event) {
            $(this).siblings('.active').removeClass('active');
            $(this).addClass('active');
            event.preventDefault();
        });
        /*=== Portfolio filtering ===*/
        $('.cs_isotop_filter ul').on('click', 'a', function () {
            var filterElement = $(this).attr('data-filter');
            $('.cs_isotop').isotope({
            filter: filterElement,
            });
        });
        }
    }

    /*--------------------------------------------------------------
        8. Counter Animation
    --------------------------------------------------------------*/
    function counterInit() {
        if ($.exists('.odometer')) {
          function winScrollPosition() {
            var scrollPos = $('body').scrollTop(),
              winHeight = self.innerHeight;
            var scrollPosition = Math.round(scrollPos + winHeight / 1.2);
            return scrollPosition;
          }
    
          $('.odometer').each(function () {
            var elemOffset = $(this).offset().top;
            if (elemOffset < winScrollPosition()) {
              $(this).html($(this).data('count-to'));
            }
          });
        }
        
      }

    /*--------------------------------------------------------------
        9. Progress Bar
     --------------------------------------------------------------*/
    function progressBar() {
        $('.cs_progress').each(function () {
            var progressPercentage = $(this).data('progress') + '%';
            $(this).find('.cs_progress_in').css('width', progressPercentage);
        });
    }
    /*--------------------------------------------------------------
        10. Cursor Animation
    --------------------------------------------------------------*/
    $(function () {
        // Create the new element
        var $Span1 = $('<span>', { class: 'cs_cursor_lg d' });
        var $Span2 = $('<span>', { class: 'cs_cursor_sm' });
        $('body').append($Span1);
        $('body').append($Span2);
        $('a, button').on('mouseenter', function () {
        $('.cs_cursor_lg').addClass('opacity-0');
        $('.cs_cursor_sm').addClass('opacity-0');
        });
        $('a, button').on('mouseleave', function () {
        $('.cs_cursor_lg').removeClass('opacity-0');
        $('.cs_cursor_sm').removeClass('opacity-0');
        });
    });
    function cursorMovingAnimation(event) {
        try {
          const timing = gsap.timeline({
            defaults: {
              x: event.clientX,
              y: event.clientY,
            },
          });
    
          timing
            .to('.cs_cursor_lg', {
              ease: 'power2.out',
            })
            .to(
              '.cs_cursor_sm',
              {
                ease: 'power2.out',
              },
              '-=0.4',
            );
        } catch (err) {
          console.log('err');
        }
      }
      document.addEventListener('mousemove', cursorMovingAnimation);


});

